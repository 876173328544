import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import HeaderLinks from "components/Header/HeaderLinks.js";

// css (jss)
import styles from "assets/jss/material-kit-react/views/about.js";

// Sections for this page

const useStyles = makeStyles(styles);

export default function AboutPage() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.container}>
        <Header
          color="black"
          brand="TM1PRO"
          rightLinks={<HeaderLinks />}
          fixed
          changeColorOnScroll={{
            height: 200,
            color: "white",
          }}
        />
        <div className={classes.main}>
          <h2>ABOUT PAGE</h2>
        </div>
        <div className={classes.mainRaised}>
          <h3>Impressum:</h3>
          <p>Holger Barczaitis</p>
          <p>Im Kirchenfeld 7</p>
          <p>79249 Merzhausen</p>
          <p>Deutschland</p>
          <p></p>
          <p>holger.barczaitis@tm1pro.de</p>
          <p>USt-ID DE327616641</p>
          <a href="https://tm1pro.de/dsgvo.html">DSGVO</a>
        </div>
      </div>
    </div>
  );
}
