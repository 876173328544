/*eslint-disable*/
import React from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
// react components for routing our app without refresh
import { Link } from "react-router-dom";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Tooltip from "@material-ui/core/Tooltip";

// @material-ui/icons
import { OutlinedFlagTwoTone, CloudDownload } from "@material-ui/icons";

// core components
import CustomDropdown from "components/CustomDropdown/CustomDropdown.js";
import Button from "components/CustomButtons/Button.js";

import styles from "assets/jss/material-kit-react/components/headerLinksStyle.js";

import de from "assets/img/de.png"
import en from "assets/img/en.png"

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  const classes = useStyles();
  return (
    <List className={classes.list}>
      <ListItem className={classes.listItem}>
        {}
        <Tooltip
          id="LinkedIn"
          title="Follow me on LinkedIn"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            href="https://linkedin.com/in/holger-barczaitis-155b592"
            target="_blank"
            color="transparent"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-linkedin"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <Tooltip
          id="Xing"
          title="Follow me on Xing"
          placement={window.innerWidth > 959 ? "top" : "left"}
          classes={{ tooltip: classes.tooltip }}
        >
          <Button
            color="transparent"
            href="https://www.xing.com/profile/Holger_Barczaitis"
            target="_blank"
            className={classes.navLink}
          >
            <i className={classes.socialIcons + " fab fa-xing"} />
          </Button>
        </Tooltip>
      </ListItem>
      <ListItem className={classes.listItem}>
        <CustomDropdown
          hoverColor="black"
          noLiPadding
          buttonProps={{
            className: classes.navLink,
            color: "transparent",
          }}
          buttonIcon={OutlinedFlagTwoTone}
          dropdownList={[
            <a
              href="/de"
              //target="/de"
              className={classes.dropdownLink}
            >
              <img src={de} />
              &nbsp;&nbsp; DE
            </a>,
            <a
              href="/en"
              //target="/en"
              className={classes.dropdownLink}
            >
              <img src={en} />
              &nbsp;&nbsp; EN
            </a>,
          ]}
        />
      </ListItem>
    </List>
  );
}
