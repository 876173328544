import React from "react";
import { WebView } from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components

// css (jss)
import styles from "assets/jss/material-kit-react/views/about.js";

// Sections for this page

const useStyles = makeStyles(styles);

export default function dsgvo() {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <WebView source={{ uri: "https://tm1pro.de/dsgvo.html" }} />
    </div>
  );
}
