import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.10.0";

// pages for this product
import LandingPageEN from "views/LandingPage/LandingPageEN.js";
import LandingPageDE from "views/LandingPage/LandingPageDE.js";
import BusinessCard from "components/BusinessCard/businessCard.js";
import AboutPage from "views/about/about.js";
import dsgvo from "views/about/dsgvo.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/businessCard" component={BusinessCard} />
      <Route path="/en" component={LandingPageEN} />
      <Route path="/de" component={LandingPageDE} />
      <Route path="/about" component={AboutPage} />
      <Route path="/dsgvo" component={dsgvo} />
      <Route path="/" component={LandingPageEN} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
