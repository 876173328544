import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import Header from "components/Header/Header.js";
import Footer from "components/Footer/Footer.js";
import HeaderLinks from "components/Header/HeaderLinks.js";
import Parallax from "components/Parallax/Parallax.js";

// css (jss)
import styles from "assets/jss/material-kit-react/views/landingPage.js";

// images - Icons
import ProfilePicture from "assets/img/hba.jpg";
import bg from "assets/img/bg.jpeg";
import ArrowDownwardOutlined from "@material-ui/icons/ArrowDownwardOutlined";

// Sections for this page
import BusinessCard from "components/BusinessCard/businessCard.js";
import DescriptionEN from "views/LandingPage/description/DescriptionEN.js";

const useStyles = makeStyles(styles);

export default function LandingPage() {
  const classes = useStyles();
  return (
    <div>
      <div className={classes.landing}>
        <Parallax filter small image={bg} />
        <div className={classes.container}>
          <Header
            color="transparent"
            brand="TM1PRO"
            rightLinks={<HeaderLinks />}
            fixed
            changeColorOnScroll={{
              height: 200,
              color: "white",
            }}
          />
          <div className={classes.card}>
            <BusinessCard
              name="Holger Barczaitis"
              profilePicture={ProfilePicture}
              occupation="TM1 Freelancer - Senior BI Consultant"
              linkedIn="https://linkedin.com/in/holger-barczaitis-155b592"
              xing="https://www.xing.com/profile/Holger_Barczaitis"
            />
          </div>
          <div className={classes.a}>
            <h2>A good TM1 solution is simple.</h2>
            <h3>Book a professional.</h3>
            <ArrowDownwardOutlined />
          </div>
        </div>
      </div>
      <div className={classes.afterLanding}>
        <div className={classes.container}>
          <div className={classes.main}>
            <DescriptionEN />
          </div>
        </div>
        <Footer />
      </div>
    </div>
  );
}
