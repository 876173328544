import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import Button from "components/CustomButtons/Button.js";
import styles from "assets/jss/custom/businessCard";

const useStyles = makeStyles(styles);

export default function businessCard(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container>
          <Grid item>
            <div className={classes.leftColumn}>
              <img
                className={classes.img}
                alt="Profilbild"
                src={props.profilePicture}
              />
            </div>
          </Grid>
          <Grid
            item
            xs
            container
            direction="column"
            className={classes.stackingContext}
          >
            <Grid item xs className={classes.rightColumn}>
              <h3 className={classes.cardTitle}>{props.name}</h3>
              <h6 style={{ marginTop: 0 }}>{props.occupation}</h6>
              <div className={classes.visitSocials}>
                <Button justIcon link href={props.linkedIn}>
                  <i className={"fab fa-linkedin"} />
                </Button>
                <Button justIcon link href={props.xing}>
                  <i className={"fab fa-xing"} />
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
}
