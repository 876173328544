import React from "react";
// nodejs library that concatenates classes
//import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

import styles from "assets/jss/custom/description.js";

const useStyles = makeStyles(styles);

export default function DescriptionEN() {
  const classes = useStyles();
  return (
    <div className={classes.description}>
      <h3>My background:</h3>
      <p>&nbsp;30+ years of professional experience</p>
      <p>&nbsp;15+ years of BI consulting</p>
      <p>&nbsp;10+ years of IBM Cognos TM1</p>
      <p>&nbsp;Commercial education</p>
      <p>&nbsp;Studies in education and psychology</p>
      <p>&nbsp;Project-safe English</p>
      <h3>Here is what you can expect from me:</h3>
      <p>&nbsp;Open communication</p>
      <p>&nbsp;A clear concept</p>
      <p>&nbsp;Short, structured meetings</p>
      <p>&nbsp;Rapid implementation</p>
      <p>&nbsp;Clean Code</p>
      <h3>I&apos;m not very good at:</h3>
      <p>&nbsp;Buzzwords</p>
      <p>&nbsp;Fachchinesisch</p>
      <p>&nbsp;PowerPoint-Orgien</p>
      <h2>I look forward to your inquiry.</h2>
      <hr />
      <h3>Focus of my recent TM1 projects:</h3>
      <p>&nbsp;TI processes and ETL</p>
      <p>&nbsp;Interfaces to other systems incl SAP BW 7.3 / 7.5 on Hana</p>
      <p>&nbsp;Performance Optimization</p>
      <p>&nbsp;Process Parallelization</p>
      <p>&nbsp;Automated System Monitoring and Maintenance</p>
      <p>&nbsp;Continuous Improvement</p>
      <h3>Skills around TM1</h3>
      <div className={classes.columnLeft}>
        <p>&nbsp;SAP BW</p>
        <p>&nbsp;SQL Server</p>
        <p>&nbsp;SQL</p>
        <p>&nbsp;MS Excel</p>
        <p>&nbsp;Jedox</p>
        <p>&nbsp;CW Importer</p>
        <p>&nbsp;Trainings</p>
      </div>
      <div className={classes.columnRight}>
        <p>TM1 connection expert</p>
        <p>good</p>
        <p>excellent</p>
        <p>excellent</p>
        <p>former Jedox consultant</p>
        <p>basic knowledge</p>
        <p>professional level in German and English</p>
      </div>
      <h3>Expertise since …</h3>
      <div className={classes.columnLeft}>
        <p>&nbsp;1987</p>
        <p>&nbsp;1990</p>
        <p>&nbsp;1993</p>
        <p>&nbsp;1998</p>
        <p>&nbsp;2000</p>
        <p>&nbsp;2004</p>
        <p>&nbsp;2005</p>
        <p>&nbsp;2011</p>
      </div>
      <div className={classes.columnRight}>
        <p>Business Software Development and Support</p>
        <p>Business Intelligence</p>
        <p>POS and ERP Consulting</p>
        <p>AS/400 ERP and PPS Software QA</p>
        <p>SQL Databases and ERP Systems</p>
        <p>OLAP Databases</p>
        <p>Business Intelligence Consultant</p>
        <p>TM1 Projects in major industrial companies</p>
      </div>
      <h3>Education</h3>
      <div className={classes.columnLeft}>
        <p>&nbsp;1969 - 1978</p>
        <p>&nbsp;1980 - 1986</p>
        <p>&nbsp;1987 - 1989</p>
      </div>
      <div className={classes.columnRight}>
        <p>Grammar School</p>
        <p>Studies in education and psychology</p>
        <p>Vocational Training in Wholesale Management</p>
      </div>
      <h3>Personal details</h3>
      <p>
        Born in Darmstadt. Happily married, four children. I live near Freiburg
        in Southwest Germany.
      </p>
      <hr />
    </div>
  );
}
