import React from "react";
// nodejs library that concatenates classes
//import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/icons

// core components

import styles from "assets/jss/custom/description.js";

const useStyles = makeStyles(styles);

export default function DescriptionDE() {
  const classes = useStyles();
  return (
    <div className={classes.description}>
      <h3>Das bringe ich mit:</h3>
      <p>&nbsp;über 30 Jahre Branchenerfahrung</p>
      <p>&nbsp;über 15 Jahre BI-Beratung</p>
      <p>&nbsp;10 Jahre IBM Cognos TM1</p>
      <p>&nbsp;kaufmännische Ausbildung</p>
      <p>&nbsp;Pädagogik- und Psychologiestudium</p>
      <p>&nbsp;projektsicheres Englisch</p>
      <h3>Das können Sie von mir erwarten:</h3>
      <p>&nbsp;offene Kommunikation</p>
      <p>&nbsp;ein klares Konzept</p>
      <p>&nbsp;kurze, strukturierte Meetings</p>
      <p>&nbsp;zügige Umsetzung</p>
      <p>&nbsp;sauberen Code</p>
      <h3>Das kann ich nicht so gut:</h3>
      <p>&nbsp;Buzzwords</p>
      <p>&nbsp;Fachchinesisch</p>
      <p>&nbsp;PowerPoint-Orgien</p>
      <h2>Ich freue mich auf Ihre Anfrage.</h2>
      <hr />
      <h3>Schwerpunkte der aktuellen TM1-Projekte:</h3>
      <p>&nbsp; TI-Prozesse und ETL</p>
      <p>&nbsp; Schnittstellen u.a. zu SAP BW 7.3 / 7.5 on Hana</p>
      <p>&nbsp; Performanceoptimierung</p>
      <p>&nbsp; Parallelisierung von Prozessen</p>
      <p>&nbsp; Automatisierte Systemüberwachung und -wartung</p>
      <p>&nbsp; Continuous Improvement</p>
      <h3>Fachkenntnisse im Umfeld von TM1</h3>
      <div className={classes.columnLeft}>
        <p>&nbsp;SAP BW</p>
        <p>&nbsp;SQL Server</p>
        <p>&nbsp;SQL</p>
        <p>&nbsp;MS Excel</p>
        <p>&nbsp;Jedox</p>
        <p>&nbsp;CW Importer</p>
        <p>&nbsp;Schulungen</p>
      </div>
      <div className={classes.columnRight}>
        <p>Experte in der TM1-Anbindung</p>
        <p>gut</p>
        <p>sehr gut</p>
        <p>sehr gut</p>
        <p>ehemaliger Jedox-Consultant</p>
        <p>Grundkenntnisse</p>
        <p>Professionell auf Deutsch und Englisch</p>
      </div>
      <h3>Fachkenntnisse seit …</h3>
      <div className={classes.columnLeft}>
        <p>&nbsp;1987</p>
        <p>&nbsp;1990</p>
        <p>&nbsp;1993</p>
        <p>&nbsp;1998</p>
        <p>&nbsp;2000</p>
        <p>&nbsp;2004</p>
        <p>&nbsp;2005</p>
        <p>&nbsp;2011</p>
      </div>
      <div className={classes.columnRight}>
        <p>Entwicklung und Support von Business-Software</p>
        <p>Business Intelligence</p>
        <p>POS- und ERP-Beratung</p>
        <p>AS/400 ERP- und PPS-Software</p>
        <p>SQL-Datenbanken und ERP-Systeme</p>
        <p>OLAP-Datenbanken</p>
        <p>Business Intelligence-Beratung</p>
        <p>TM1-Projekte in großen Industrieunternehmen</p>
      </div>
      <h3>Ausbildung</h3>
      <div className={classes.columnLeft}>
        <p>&nbsp;1969 - 1978</p>
        <p>&nbsp;1980 - 1986</p>
        <p>&nbsp;1987 - 1989</p>
      </div>
      <div className={classes.columnRight}>
        <p>Gymnasium</p>
        <p>Studium in Pädagogik und Psychologie</p>
        <p>
          Berufsausbildung: Kaufmann im Groß-
          <br />
          und Einzelhandel
        </p>
      </div>
      <h3>Persönliches</h3>
      <p>
        Geboren in Darmstadt. Glücklich verheiratet, vier Kinder. Ich lebe in
        der Nähe von Freiburg i.Br.
      </p>
      <hr />
    </div>
  );
}
