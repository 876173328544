const descriptionStyle = {
  description: {},
  columnLeft: {
    width: "25%",
    float: "left",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
  columnRight: {
    overflow: "hidden",
    whiteSpace: "nowrap",
  },
};

export default descriptionStyle;
