import { container, title } from "assets/jss/material-kit-react.js";

const landingPageStyle = {
  container: {
    zIndex: "12",
    color: "#ffffff",
    ...container,
  },
  title: {
    ...title,
    textAlign: "center",
    position: "relative",
    fontSize: "50px",
    fontFamily: "Arial",
    fontWeight: "200",
    textDecoration: "none",
  },
  a: {
    position: "absolute",
    width: "80%",
    margin: "auto",
    textAlign: "center",
    fontFamily: "Arial",
    fontSize: "45px",
    fontWeight: "100",
    zIndex: "5",
    left: "50%",
    webkitTransform: "translate(-50%, 0)",
    transform: "translate(-50%, 0)",
  },
  main: {
    background: "#FFFFFF",
    color: "#000000",
    zIndex: "3",
    padding: "16px",
    borderRadius: "5px",
  },
  mainRaised: {
    margin: "-60px 30px 0px",
    borderRadius: "6px",
    boxShadow:
      "0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.2)",
  },
  card: {
    width: "500px",
    maxWidth: "90%",
    position: "absolute",
    top: "50%",
    left: "50%",
    webkitTransform: "translate(-50%, -50%)",
    transform: "translate(-50%, -50%)",
  },
  landing: {
    position: "absolute",
    height: "100%",
    width: "100%",
    background: "#000000",
  },
  afterLanding: {
    position: "absolute",
    width: "100%",
    top: "100%",
    background: "#000000",
  },
  icon: {
    fontFamily: "",
  },
};

export default landingPageStyle;
