import { title } from "assets/jss/material-kit-react.js";

const profilePageStyle = {
  root: {
    flexGrow: 1,
  },
  paper: {
    maxWidth: 500,
  },
  leftColumn: {
    height: 256,
    width: "auto",
  },
  rightColumn: {
    padding: "10px",
    height: 256,
    width: "auto",
  },
  stackingContext: {
    position: "relative",
  },
  img: {
    margin: "auto",
    display: "block",
    maxWidth: "100%",
    maxHeight: "100%",
    borderRadius: "4px 0 0 4px",
  },
  cardTitle: {
    ...title,
    display: "inline-block",
    position: "relative",
    marginTop: "0px",
    marginBottom: "2px",
    textDecoration: "none",
    fontFamily: "Arial",
    fontWeight: "300",
    color: "rgb(50,50,100)",
  },
  visitSocials: {
    position: "absolute",
    bottom: "0",
    left: "0",
  },
};

export default profilePageStyle;
